import { Image } from 'components/Image'
import { useEffect, useState, useContext } from 'react'
import { debounce as _debounce } from 'lodash-es'
import useMobileNavState from 'hooks/useMobileNavState'
import useIsSsr from 'hooks/useIsSsr'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Box, Button, Typography, AchieveTheme } from '@achieve/sunbeam'
import Container from '@mui/material/Container'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { SignInLink } from 'components/SignInLink'
import Navigation from 'components/Navigation'
import HeaderMobileActions from './HeaderMobileActions'
import HeaderCta from './HeaderCta'
import styles from './Header.module.scss'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'
import { LayoutContext } from 'providers/LayoutProvider'
import { Phone as PhoneIcon, X as CloseIcon } from 'react-feather'
import { PhoneDataContext } from 'providers/PhoneDataProvider'

// Percentage of the window that needs to scroll for the mobile header to condense
const MOBILE_CONDENSED_THRESHOLD = 0.3

/**
 * Main site Header component
 * @param {{
 *  mainNavigation: object
 *  disabledRoutes: []
 * }} props
 */
export default function Header({
  disabledElements,
  disabledRoutes,
  mainNavigation,
  logoFileName,
  logoFileUrl,
  signInLinkUrl,
  signInLinkText,
  linkUrl,
  linkText,
  noHeaderCta,
  showNav = true,
}) {
  const [, setLayoutMenuCondensed] = useLayoutMenuCondensed()
  const { dispatch } = useContext(AnalyticsContext)
  const [mobileNavOpen, setMobileNavOpen] = useMobileNavState()
  const [condensed, setCondensed] = useState(false)
  const [mobileCondensed, setMobileCondensed] = useState(false)
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const [buttonCall, setButtonCall] = useState(false)
  const phoneData = useContext(PhoneDataContext)
  const { state: { isMobileUA } = {} } = useContext(LayoutContext)

  // Default to mobile first before initial render if the user agent matches a mobile device
  const isMobile = isSsr ? isMobileUA : mqHookResults

  const debounceOptions = {
    leading: true,
    trailing: false,
  }

  const setMobileCondensedTrue = _debounce(() => setMobileCondensed(true), 100, debounceOptions)
  const setMobileCondensedFalse = _debounce(() => setMobileCondensed(false), 100, debounceOptions)
  const setCondensedTrue = _debounce(() => setCondensed(true), 100, debounceOptions)
  const setCondensedFalse = _debounce(() => setCondensed(false), 100, debounceOptions)

  /**
   * Set the initial condensed state based on window scroll position
   */
  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }

    onScroll()
    // This effect is only intended to run on the first browser (not SSR) render
  }, [isSsr]) /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  function onScroll() {
    if (isMobile) {
      return handleMobileScroll()
    }
    return handleDesktopScroll()
  }

  /**
   * Enable the condensed mobile header when the page is scrolled passed the threshold
   */
  function handleMobileScroll() {
    const currentScroll = window.scrollY
    const nextMobileCondensed = currentScroll > window.innerHeight * MOBILE_CONDENSED_THRESHOLD

    if (nextMobileCondensed === mobileCondensed) {
      return
    }

    if (nextMobileCondensed) {
      return setMobileCondensedTrue()
    }

    return setMobileCondensedFalse()
  }

  /**
   * Enable the condensed desktop header when the page is scrolled any distance from the top
   */
  function handleDesktopScroll() {
    const currentScroll = window.scrollY
    const nextCondensed = Boolean(currentScroll)

    if (nextCondensed === condensed) {
      return
    }

    if (nextCondensed) {
      return setCondensedTrue()
    }

    return setCondensedFalse()
  }

  /*
    Prevent body scroll when the mobile navigation menu is open
  */
  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (isMobile && mobileNavOpen) {
      // Prevent the body scrolling under the open mobile nav menu by fixing the body element,
      // setting the height to the viewport height and setting the overflow to hidden
      body.style.position = 'fixed'
      body.style.height = '100vh'
      body.style.width = '100vw'
      body.style.overflow = 'hidden'
      return
    }

    // Reset the body css to scroll and overflow normally.
    body.style.position = 'initial'
    body.style.height = 'initial'
    body.style.width = 'initial'
    body.style.overflow = 'initial'
  }, [isMobile, mobileNavOpen])

  const handleCloseClick = (event, track) => {
    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: {
        event_type: track?.event_type,
        ...handleTrackAndReactEvent(event, track),
      },
    })
    !isSsr && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen(false)
  }

  const handleOpenClick = (event, track) => {
    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: {
        event_type: track?.event_type,
        ...handleTrackAndReactEvent(event, track),
      },
    })
    setMobileNavOpen(true)
  }

  useEffect(() => {
    setLayoutMenuCondensed(isMobile ? mobileCondensed : condensed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, condensed, mobileCondensed])

  const callToAssociatedNumber = () => {
    if (phoneData?.phoneNumber) window.open(`tel:${phoneData.phoneNumber}`, '_self')
  }

  const handleCall = () => {
    if (isMobile) callToAssociatedNumber()
    else setButtonCall(!buttonCall)
  }

  const getSubNav = () => {
    if (!isMobile || !phoneData?.phoneNumber || !showNav) return ''
    return (
      <Box className={styles['header-subnav']}>
        <Typography
          variant="displayS10"
          fontWeight="regular"
          data-testid="header-subnav-phone-copy"
        >
          {phoneData?.phoneCopy}
        </Typography>
        <AchieveLink
          className={styles['title-link']}
          noLink
          track={{
            list_name: 'PHONE CALL CLICK HEADER',
            click_id: phoneData?.phoneNumber,
            click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
            click_url: phoneData?.phoneNumber,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<PhoneIcon />}
            onClick={callToAssociatedNumber}
            data-testid="header-subnav-phone-button"
          >
            {phoneData?.phoneNumber}
          </Button>
        </AchieveLink>
      </Box>
    )
  }

  const getMobileIcon = () => {
    return (
      <PhoneIcon
        width={condensed ? 20 : 24}
        height={condensed ? 20 : 24}
        color={AchieveTheme?.sb?.colors?.primary?.achieveBlue}
        onClick={handleCall}
      />
    )
  }

  return (
    <header
      className={styles.header}
      data-condensed={condensed}
      data-mobile-condensed={mobileCondensed}
      data-mobile-nav-open={mobileNavOpen}
      data-narrow={true}
      data-testid="acx-website-header"
    >
      <Container
        maxWidth={false}
        disableGutters
        className={styles['header-content-container']}
        data-narrow={true}
      >
        <div className={styles['header-content']}>
          {/* Container for content shown in the mobile header bar */}
          <div className={styles['mobile-header']}>
            <AchieveLink
              track={{
                list_name: 'Header Logo',
                click_id: logoFileName ? logoFileName : '2022_Achieve_Logo_RGB',
              }}
              href="/"
              withNextLink
              className={styles['logo']}
              data-testid="achieve-header-logo"
            >
              <Image
                src={logoFileUrl ? logoFileUrl : '/2022_Achieve_Logo_RGB.svg'}
                alt="Achieve Logo"
                layout="fill"
                priority={true}
              />
            </AchieveLink>

            <AchieveLink
              track={{
                list_name: 'Header Logo',
                click_id: logoFileName ? logoFileName : '2022_Achieve_Logomark_RGB',
              }}
              href="/"
              withNextLink
              className={styles['mobile-logo']}
              data-testid="achieve-header-mobile-logo"
              onClick={() => setMobileNavOpen(false)}
            >
              <Image
                src="/2022_Achieve_Logomark_RGB.svg"
                alt="Achieve Mobile Logo"
                layout="fill"
                priority={false}
              />
            </AchieveLink>
            {showNav && (
              <>
                {/* Mobile nav menu open and close buttons */}
                <div className={styles['mobile-menu-controls']}>
                  {isMobile && phoneData?.phoneNumber && (
                    <Box
                      className={styles['call-cta']}
                      data-call-cta-mobile={true}
                      data-open-button-call={buttonCall}
                    >
                      <AchieveLink
                        className={styles['call-cta-link']}
                        noLink
                        track={{
                          list_name: 'PHONE CALL CLICK HEADER',
                          click_id: phoneData?.phoneNumber,
                          click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                          click_url: phoneData?.phoneNumber,
                        }}
                        data-testid="mobile-phone-number-link"
                      >
                        {getMobileIcon()}
                      </AchieveLink>
                    </Box>
                  )}
                  <button
                    onClick={(e) => {
                      handleOpenClick(e, {
                        nav_link_section: 'Toggle menu',
                        click_type: 'Button Click',
                        click_id: 'open button',
                      })
                    }}
                    className={styles['mobile-menu-button']}
                    data-testid="mobile-nav-open-button"
                    aria-label="Main navigation menu button"
                  >
                    {/*
                  When using next/image, on some devices, including iPhone Xs,
                  the hamburger icon is small and it disappears after tapping close.
                */}
                    {/* eslint-disable-next-line @next/next/no-img-element, react/no-unknown-property */}
                    <Image
                      src="/menu.svg"
                      alt="Menu button"
                      width={18}
                      height={12}
                      priority={isMobile}
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      handleCloseClick(e, {
                        nav_link_section: 'Toggle menu',
                        click_type: 'Button Click',
                        click_id: 'close button',
                      })
                    }}
                    role="button"
                    className={styles['mobile-close-button']}
                    data-testid="mobile-nav-close-button"
                    aria-label="Main navigation menu close button"
                  >
                    <Typography variant="bodyS20" fontWeight="medium" component="span">
                      CLOSE
                    </Typography>
                    <Image
                      src="/close.svg"
                      alt="Close button"
                      width={12}
                      height={12}
                      priority={false}
                      fetchpriority="low"
                      aria-hidden={true}
                    />
                  </button>
                </div>
              </>
            )}
          </div>
          {showNav && (
            <>
              {/* Main nav and sub nav list components */}
              <Navigation
                condensed={condensed}
                mobileNavOpen={mobileNavOpen}
                items={mainNavigation}
                disabledRoutes={disabledRoutes}
                disabledElements={disabledElements}
                mobileSubnav={getSubNav()}
              />
              {/* Right Panel */}
              <div
                className={styles['header-right-section']}
                data-open-button-call={buttonCall}
                data-none-phone-number={phoneData?.phoneNumber ? false : true}
              >
                {/* "Get Started" CTA button is always present in desktop and is hidden and shown on
                scroll in mobile. */}
                {!noHeaderCta && (
                  <HeaderCta
                    linkUrl={linkUrl}
                    linkText={linkText}
                    size={isMobile || condensed ? 'xsmall' : 'small'}
                    className={styles['header-cta']}
                    data-testid="header-get-started-button"
                  />
                )}
                {phoneData?.phoneNumber && !isMobile && (
                  <Box className={styles['call-cta']}>
                    <Box className={styles['call-cta-container']}>
                      {getMobileIcon()}
                      <Box className={styles['button-call-link']}>
                        <AchieveLink
                          noLink
                          track={{
                            list_name: 'PHONE CALL CLICK HEADER',
                            click_id: phoneData?.phoneNumber,
                            click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                            click_url: phoneData?.phoneNumber,
                          }}
                          data-testid="desktop-phone-number-link"
                        >
                          <Typography
                            variant="bodyXS30"
                            fontWeight="regular"
                            onClick={() => callToAssociatedNumber()}
                            data-testid="desktop-phone-number"
                          >
                            {phoneData?.phoneNumber}
                          </Typography>
                        </AchieveLink>
                      </Box>
                      <CloseIcon
                        className={styles['icon-close-link']}
                        width={18}
                        height={18}
                        color={AchieveTheme?.sb?.colors?.neutral?.grey?.[3]}
                        onClick={handleCall}
                      />
                    </Box>
                  </Box>
                )}
                <SignInLink
                  signInLinkUrl={signInLinkUrl}
                  signInLinkText={signInLinkText}
                  className={styles['sign-in-link']}
                  data-testid="header-sign-in-link"
                  disabledRoutes={disabledRoutes}
                />
              </div>
              {/* Action bar that shows at the bottom of the mobile nav */}
              {isMobile && (
                <HeaderMobileActions disabledRoutes={disabledRoutes} noHeaderCta={noHeaderCta} />
              )}
            </>
          )}
        </div>
      </Container>
    </header>
  )
}
